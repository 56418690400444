import {
  AutofillOptions,
  AutofillRetrieveResponse,
  AutofillSuggestion,
  AutofillSuggestionResponse,
  MapboxAutofill,
  SearchSession
} from '@mapbox/search-js-core';

import { MapboxSearchListbox } from './MapboxSearchListbox';
import { HTMLScopedElement } from './HTMLScopedElement';

import { tryParseJSON } from '../utils';
import { fillFormWithFeature } from '../utils/autofill';
import { tryConfirmBrowserAutofill } from '../utils/confirmation';

import { Theme } from '../theme';
import { MapboxHTMLEvent } from '../MapboxHTMLEvent';
import { config } from '../config';
import { initDetectBrowserAutofill } from '../utils/detect_browser_autofill';
import { AddressConfirmOptions } from './MapboxAddressConfirmation';

export type MapboxSearchListboxAutofillType = MapboxSearchListbox<
  AutofillOptions,
  AutofillSuggestion,
  AutofillSuggestionResponse,
  AutofillRetrieveResponse
>;

type AutofillEventTypes = {
  /**
   * Fired when the user is typing in the input and provides a list of suggestions.
   *
   * The underlying response from {@link MapboxAutofill} is passed as the event's detail.
   *
   * @event suggest
   * @instance
   * @memberof MapboxAddressAutofill
   * @type {AutofillSuggestionResponse}
   * @example
   * ```typescript
   * autofill.addEventListener('suggest', (event) => {
   *   const suggestions = event.detail.suggestions;
   *   // ...
   * });
   * ```
   */
  suggest: MapboxHTMLEvent<AutofillSuggestionResponse>;
  /**
   * Fired when {@link MapboxAutofill} has errored providing a list of suggestions.
   *
   * The underlying error is passed as the event's detail.
   *
   * @event suggesterror
   * @instance
   * @memberof MapboxAddressAutofill
   * @type {Error}
   * @example
   * ```typescript
   * autofill.addEventListener('suggesterror', (event) => {
   *   const error = event.detail;
   *   // ...
   * });
   * ```
   */
  suggesterror: MapboxHTMLEvent<Error>;
  /**
   * Fired when the user has selected a suggestion, before the form is autofilled.
   *
   * The underlying response from {@link MapboxAutofill} is passed as the event's detail.
   *
   * @event retrieve
   * @instance
   * @memberof MapboxAddressAutofill
   * @type {AutofillRetrieveResponse}
   * @example
   * ```typescript
   * autofill.addEventListener('retrieve', (event) => {
   *   const featureCollection = event.detail;
   *   // ...
   * });
   * ```
   */
  retrieve: MapboxHTMLEvent<AutofillRetrieveResponse>;
};

/**
 * `MapboxAddressAutofill`, also available as the element `<mapbox-address-autofill>`,
 * is an element that wraps an address [`<input>`](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/text) element with
 * intelligent, location-aware autocomplete functionality.
 *
 * To use this element, you must have a [Mapbox access token](https://www.mapbox.com/help/create-api-access-token/).
 *
 * This element must be a descendant of a [`<form>`](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/form) element, and the form
 * must have inputs with proper HTML `autocomplete` attributes. If your application works with browser autofill, you may already have
 * this functionality.
 * - [The HTML autocomplete attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete)
 * - [Autofill](https://web.dev/learn/forms/autofill/)
 *
 * @class MapboxAddressAutofill
 * @example
 * <form>
 *   <mapbox-address-autofill access-token="<your access token here>">
 *     <input type="text" name="address" autocomplete="shipping street-address" />
 *   </mapbox-address-autofill>
 * </form>
 */
export class MapboxAddressAutofill extends HTMLScopedElement<AutofillEventTypes> {
  /**
   * This is read by the Web Components API to affect the
   * {@link MapboxAddressAutofill.attributeChangedCallback} below.
   *
   * All of these are passthroughs to the underlying {@link MapboxSearchListbox}.
   *
   * @ignore
   */
  static observedAttributes: string[] = [
    // Access token.
    'access-token',
    // Theming, CSS.
    'theme',
    'css-text',
    // Underlying Autofill API options.
    'language',
    'country',
    'bbox',
    'limit',
    'proximity'
  ];

  #autofill = new MapboxAutofill();
  #session = new SearchSession(this.#autofill);

  /**
   * The [Mapbox access token](https://docs.mapbox.com/help/glossary/access-token/) to use for all requests.
   *
   * @name accessToken
   * @instance
   * @memberof MapboxAddressAutofill
   * @example
   * ```typescript
   * autofill.accessToken = 'pk.my-mapbox-access-token';
   * ```
   */
  get accessToken(): string {
    return this.#autofill.accessToken;
  }
  set accessToken(newToken: string) {
    this.#autofill.accessToken = newToken;
  }

  #input: HTMLInputElement;
  #listbox: MapboxSearchListboxAutofillType = new MapboxSearchListbox();

  /**
   * Options to pass to the underlying {@link MapboxAutofill} interface.
   *
   * @name options
   * @instance
   * @memberof MapboxAddressAutofill
   * @type {AutofillOptions}
   * @example
   * ```typescript
   * autofill.options = {
   *  language: 'en',
   *  country: 'US',
   * };
   * ```
   */
  get options(): Partial<AutofillOptions> {
    return this.#listbox.options;
  }
  set options(newOptions: Partial<AutofillOptions>) {
    this.#listbox.options = newOptions;
  }

  /**
   * The {@link Theme} to use for styling the autofill component.
   *
   * @name theme
   * @instance
   * @memberof MapboxAddressAutofill
   * @type {Theme}
   * @example
   * ```typescript
   * autofill.theme = {
   *   variables: {
   *     colorPrimary: 'myBrandRed'
   *   }
   * };
   * ```
   */
  get theme(): Theme {
    return this.#listbox.theme;
  }
  set theme(theme: Theme) {
    this.#listbox.theme = theme;
  }

  /**
   * If true, forms autofilled by the browser will prompt the
   * {@link confirmAddress} dialog for user confirmation.
   * An {@link AddressConfirmOptions} object can also be passed
   * to prompt {@link confirmAddress} with custom options.
   * Defaults to false.
   *
   * @name confirmOnBrowserAutofill
   * @instance
   * @memberof MapboxAddressAutofill
   * @type {boolean | AddressConfirmOptions}
   * @example
   * ```typescript
   * autofill.confirmOnBrowserAutofill = {
   *   minimap: true,
   *   skipConfirmModal: (feature) =>
   *     ['exact', 'high'].includes(
   *       feature.properties.match_code.confidence
   *     )
   * };
   * ```
   */
  confirmOnBrowserAutofill: boolean | AddressConfirmOptions = false;

  #handleSuggest = (e: MapboxHTMLEvent<AutofillSuggestionResponse>): void => {
    // Manually bubble up the event.
    this.dispatchEvent(e.clone());
  };

  #handleSuggestError = (e: MapboxHTMLEvent<Error>): void => {
    // Manually bubble up the event.
    this.dispatchEvent(e.clone());
  };

  #handleRetrieve = (e: MapboxHTMLEvent<AutofillRetrieveResponse>): void => {
    // Manually bubble up the event.
    this.dispatchEvent(e.clone());

    if (!this.#input) {
      return;
    }

    const featureCollection = e.detail;
    if (
      !featureCollection ||
      !featureCollection.features ||
      !featureCollection.features.length
    ) {
      return;
    }

    fillFormWithFeature(featureCollection.features[0], this.#input);
  };

  // Called when content changes.
  #handleObserve = (): void => {
    try {
      const input: HTMLInputElement = this.querySelector('input') ?? null;

      this.#input = input;
      this.#listbox.input = input;
    } catch (e) {
      this.#input = null;
      this.#listbox.input = null;

      console.error(e.message || e);
    }
  };

  #observer = new MutationObserver(this.#handleObserve);

  #handleBrowserAutofill = (e: CustomEvent): void => {
    // Hide listbox, if showing
    this.#listbox.blur();

    // Address confirmation
    tryConfirmBrowserAutofill(
      this.#input,
      e,
      this.confirmOnBrowserAutofill,
      this.accessToken
    );
  };

  connectedCallback(): void {
    super.connectedCallback();

    config.autofillSessionEnabled = true;

    // Bind the listbox to the session.
    this.#listbox.session = this.#session;
    this.#listbox.session.sessionToken = config.autofillSessionToken;

    this.#listbox.addEventListener('suggest', this.#handleSuggest);
    this.#listbox.addEventListener('suggesterror', this.#handleSuggestError);
    this.#listbox.addEventListener('retrieve', this.#handleRetrieve);

    document.body.appendChild(this.#listbox);

    // Setup observer handler.
    this.#observer.observe(this, {
      subtree: true,
      childList: true
    });

    this.#handleObserve();

    // Setup browser autofill detection
    initDetectBrowserAutofill();
    window.addEventListener('browserautofill', this.#handleBrowserAutofill);
  }

  disconnectedCallback(): void {
    this.#listbox.remove();

    this.#listbox.removeEventListener('suggest', this.#handleSuggest);
    this.#listbox.removeEventListener('suggesterror', this.#handleSuggestError);
    this.#listbox.removeEventListener('retrieve', this.#handleRetrieve);

    this.#observer.disconnect();

    window.removeEventListener('browserautofill', this.#handleBrowserAutofill);
  }

  attributeChangedCallback(
    name: string,
    oldValue: string,
    newValue: string
  ): void {
    if (name === 'access-token') {
      this.#autofill.accessToken = newValue;
      return;
    }

    if (name === 'theme') {
      this.theme = tryParseJSON(newValue);
      return;
    }

    // Convert to the proper name for options.
    // Example: eta-type => eta_type
    const optionName = name.split('-').join('_');

    if (!newValue) {
      delete this.#listbox.options[optionName];
    }

    // Otherwise, assume it's a Search API option.
    this.#listbox.options[optionName] = newValue;
  }

  /** @section {Methods} */

  /**
   * Focuses the wrapped input element.
   */
  focus(): void {
    this.#listbox.focus();
  }
}

declare global {
  interface Window {
    MapboxAddressAutofill: typeof MapboxAddressAutofill;
  }
}

window.MapboxAddressAutofill = MapboxAddressAutofill;

if (!window.customElements.get('mapbox-address-autofill')) {
  customElements.define('mapbox-address-autofill', MapboxAddressAutofill);
}
