import {
  featureToSuggestion,
  LngLatBounds,
  MapboxSearch,
  Options,
  RetrieveResponse,
  Suggestion,
  SuggestionResponse,
  SearchSession
} from '@mapbox/search-js-core';
import mapboxgl from 'mapbox-gl';

import bbox from '@turf/bbox';
import bboxPolygon from '@turf/bbox-polygon';

import { MapboxSearchListbox } from './MapboxSearchListbox';
import { HTMLScopedElement } from './HTMLScopedElement';

import { FlatLngLatBounds, tryParseJSON } from '../utils';
import { createElementFromString } from '../utils/dom';
import { bboxViewport, FLY_TO_SPEED, getMaxZoom } from '../utils/map';

import { Theme, getThemeCSS } from '../theme';
import { MapboxHTMLEvent } from '../MapboxHTMLEvent';

import style from '../style.css';

/**
 * Proximity is designed for local scale. If the user is looking at the whole world,
 * it doesn't make sense to factor in the arbitrary center of the map.
 */
const MAX_ZOOM = 9;

const TEMPLATE = createElementFromString<HTMLTemplateElement>(`
<template>
  <input class="Input" type="text" />
</template>
`);

export type MapboxSearchListboxSearchType = MapboxSearchListbox<
  Options,
  Suggestion,
  SuggestionResponse,
  RetrieveResponse
>;

type SearchEventTypes = {
  /**
   * Fired when the user is typing and is provided a list of suggestions.
   *
   * The underlying response from {@link MapboxSearch} is passed as the event's detail.
   *
   * @event suggest
   * @instance
   * @memberof MapboxSearchBox
   * @type {SuggestionResponse}
   * @example
   * ```typescript
   * search.addEventListener('suggest', (event) => {
   *   const suggestions = event.detail.suggestions;
   *   // ...
   * });
   * ```
   */
  suggest: MapboxHTMLEvent<SuggestionResponse>;
  /**
   * Fired when {@link MapboxSearch} has errored providing a list of suggestions.
   *
   * The underlying error is passed as the event's detail.
   *
   * @event suggesterror
   * @instance
   * @memberof MapboxSearchBox
   * @type {Error}
   * @example
   * ```typescript
   * search.addEventListener('suggesterror', (event) => {
   *   const error = event.detail;
   *   // ...
   * });
   * ```
   */
  suggesterror: MapboxHTMLEvent<Error>;
  /**
   * Fired when the user has selected a suggestion.
   *
   * The underlying response from {@link MapboxSearch} is passed as the event's detail.
   *
   * @event retrieve
   * @instance
   * @memberof MapboxSearchBox
   * @type {RetrieveResponse}
   * @example
   * ```typescript
   * search.addEventListener('retrieve', (event) => {
   *   const featureCollection = event.detail;
   *   // ...
   * });
   * ```
   */
  retrieve: MapboxHTMLEvent<RetrieveResponse>;
  input: MapboxHTMLEvent<unknown>;
};

/**
 * `MapboxSearchBox`, also available as the element `<mapbox-search-box>`,
 * is an element that lets you search for places, addresses, and landmarks using
 * the [Mapbox Search API](https://docs.mapbox.com/api/search/search).
 *
 * It can control a [Mapbox GL JS](https://docs.mapbox.com/mapbox-gl-js/guides/) map
 * to zoom to the selected result.
 *
 * Additionally, `MapboxSearchBox` implements the [IControl](https://www.mapbox.com/mapbox-gl-js/api/markers/#icontrol)
 * interface.
 *
 * To use this element, you must have a [Mapbox access token](https://www.mapbox.com/help/create-api-access-token/).
 *
 * @class MapboxSearchBox
 * @example
 * ```typescript
 * const search = new MapboxSearchBox();
 * search.accessToken = '<your access token here>';
 * map.addControl(search);
 * ```
 * @example
 * <mapbox-search-box
 *   access-token="<your access token here>"
 *   proximity="0,0"
 * >
 * </mapbox-search-box>
 */
export class MapboxSearchBox
  extends HTMLScopedElement<SearchEventTypes>
  implements mapboxgl.IControl
{
  /**
   * This is read by the Web Components API to affect the
   * {@link MapboxSearchBox#attributeChangedCallback} below.
   *
   * All of these are passthroughs to the underlying {@link MapboxSearchListbox}.
   *
   * @ignore
   */
  static observedAttributes: string[] = [
    // Access token.
    'access-token',
    // Theming.
    'theme',
    // Underlying Search API options.
    'language',
    'country',
    'bbox',
    'limit',
    'navigation-profile',
    'origin',
    'proximity',
    'eta-type',
    'types'
  ];

  #search = new MapboxSearch({});
  #session = new SearchSession(this.#search);

  /**
   * The [Mapbox access token](https://docs.mapbox.com/help/glossary/access-token/) to use for all requests.
   *
   * @name accessToken
   * @instance
   * @memberof MapboxSearchBox
   * @example
   * ```typescript
   * search.accessToken = 'pk.my-mapbox-access-token';
   * ```
   */
  get accessToken(): string {
    return this.#search.accessToken;
  }
  set accessToken(newToken: string) {
    this.#search.accessToken = newToken;
  }

  /**
   * The value of the input element.
   *
   * @name value
   * @instance
   * @memberof MapboxSearchBox
   * @example
   * ```typescript
   * console.log(search.value);
   * ```
   */
  get value(): string {
    return this.#input.value;
  }
  set value(newValue: string) {
    this.#input.value = newValue;
  }

  #map: mapboxgl.Map | null = null;

  #input: HTMLInputElement;
  #listbox: MapboxSearchListboxSearchType = new MapboxSearchListbox();

  protected override get template(): HTMLTemplateElement {
    return TEMPLATE;
  }

  protected override get templateStyle(): string {
    return style;
  }

  protected override get templateUserStyle(): string {
    return getThemeCSS('.Input', this.#listbox.theme);
  }

  /**
   * Options to pass to the underlying {@link MapboxSearch} interface.
   *
   * @name options
   * @instance
   * @memberof MapboxSearchBox
   * @type {Options}
   * @example
   * ```typescript
   * search.options = {
   *  language: 'en',
   *  country: 'US',
   * };
   * ```
   */
  get options(): Partial<Options> {
    return this.#listbox.options;
  }
  set options(newOptions: Partial<Options>) {
    this.#listbox.options = newOptions;
  }

  /**
   * The {@link Theme} to use for styling the suggestion box and search box.
   *
   * @name theme
   * @instance
   * @memberof MapboxSearchBox
   * @type {Theme}
   * @example
   * ```typescript
   * search.theme = {
   *   variables: {
   *     colorPrimary: 'myBrandRed'
   *   },
   *   cssText: ".Input:active { opacity: 0.9; }"
   * };
   * ```
   */
  get theme(): Theme {
    return this.#listbox.theme;
  }
  set theme(theme: Theme) {
    this.#listbox.theme = theme;

    const input = this.#input;
    if (!input) {
      return;
    }

    this.updateTemplateUserStyle(getThemeCSS('.Input', theme));
    this.#listbox.updatePopover();
  }

  #handleSuggest = (e: MapboxHTMLEvent<SuggestionResponse>): void => {
    // Manually bubble up the event.
    this.dispatchEvent(e.clone());
  };

  #handleSuggestError = (e: MapboxHTMLEvent<Error>): void => {
    // Manually bubble up the event.
    this.dispatchEvent(e.clone());
  };

  #handleRetrieve = async (
    e: MapboxHTMLEvent<RetrieveResponse>
  ): Promise<void> => {
    // Manually bubble up the event.
    this.dispatchEvent(e.clone());

    const featureCollection = e.detail;
    if (!featureCollection || !featureCollection.features.length) {
      return;
    }

    // Set value of the input.
    const suggestion = featureToSuggestion(featureCollection.features[0]);
    this.#input.value = suggestion.feature_name;

    const map = this.#map;
    if (!map) {
      return;
    }

    const features = featureCollection.features;
    // Handle single feature.
    if (features.length === 1) {
      const feature = features[0];
      const placeType = feature.properties.place_type?.[0];

      const bounds = feature.bbox;
      if (bounds) {
        map.flyTo(
          bboxViewport(map, LngLatBounds.convert(bounds).toFlatArray())
        );
      } else {
        const center = feature.geometry.coordinates as mapboxgl.LngLatLike;
        const zoom = getMaxZoom(placeType);

        map.flyTo({
          center,
          zoom,
          speed: FLY_TO_SPEED
        });
      }

      return;
    }

    // Get the bounds of the feature, bbox if available.
    const geoAccurateFeatures = featureCollection.features.map((feature) => {
      if (feature.bbox) {
        return bboxPolygon(LngLatBounds.convert(feature.bbox).toFlatArray());
      }

      return feature;
    });

    const bounds = bbox({
      type: 'FeatureCollection',
      features: geoAccurateFeatures
    });
    map.flyTo(bboxViewport(map, bounds as FlatLngLatBounds));
  };

  #handleInput = (e: InputEvent): void => {
    const event = new MapboxHTMLEvent('input');
    Object.assign(event, {
      ...e
    });

    this.dispatchEvent<'input'>(event);
  };

  override connectedCallback(): void {
    super.connectedCallback();

    const input = this.querySelector<HTMLInputElement>('.Input');
    input.addEventListener('input', this.#handleInput);

    this.#input = input;

    // Bind the listbox to the session.
    this.#listbox.session = this.#session as SearchSession<
      Options,
      Suggestion,
      SuggestionResponse,
      RetrieveResponse
    >;
    this.#listbox.input = input;
    this.#listbox.addEventListener('suggest', this.#handleSuggest);
    this.#listbox.addEventListener('suggesterror', this.#handleSuggestError);
    this.#listbox.addEventListener('retrieve', this.#handleRetrieve);

    document.body.appendChild(this.#listbox);
  }

  disconnectedCallback(): void {
    this.#listbox.remove();
    this.#listbox.input = null;

    this.#listbox.removeEventListener('suggest', this.#handleSuggest);
    this.#listbox.removeEventListener('suggesterror', this.#handleSuggestError);
    this.#listbox.removeEventListener('retrieve', this.#handleRetrieve);
  }

  attributeChangedCallback(
    name: string,
    oldValue: string,
    newValue: string
  ): void {
    if (name === 'access-token') {
      this.#search.accessToken = newValue;
      return;
    }

    if (name === 'theme') {
      this.theme = tryParseJSON(newValue);
      return;
    }

    // Convert to the proper name for options.
    // Example: eta-type => eta_type
    const optionName = name.split('-').join('_');

    if (!newValue) {
      delete this.#listbox.options[optionName];
    }

    // Otherwise, assume it's a Search API option.
    this.#listbox.options[optionName] = newValue;
  }

  /** @section {Methods} */

  /**
   * Focuses the input element.
   */
  focus(): void {
    this.#listbox.focus();
  }

  #handleMoveEnd = (): void => {
    const map = this.#map;
    const options = { ...this.#listbox.options };

    if (map.getZoom() <= MAX_ZOOM) {
      delete options.proximity;
      this.#listbox.options = options;

      return;
    }

    const center = map.getCenter();
    this.#listbox.options = {
      ...options,
      proximity: center
    };
  };

  /** @section {Map binding} */

  /**
   * Connects the search box to a [Map](https://docs.mapbox.com/mapbox-gl-js/api/#map),
   * which handles both setting proximity and zoom after a suggestion click.
   *
   * @example
   * ```typescript
   * const search = new MapboxSearchBox();
   * search.bindMap(map);
   * ```
   */
  bindMap(map: mapboxgl.Map): void {
    if (this.#map) {
      this.#map.off('moveend', this.#handleMoveEnd);
    }

    if (map) {
      map.on('moveend', this.#handleMoveEnd);
    }

    this.#map = map;
  }

  /**
   * Unbinds the search box from a [Map](https://docs.mapbox.com/mapbox-gl-js/api/#map).
   */
  unbindMap(): void {
    this.bindMap(null);
  }

  // IControl interface.

  // eslint-disable-next-line custom-elements/no-method-prefixed-with-on
  onAdd(map: mapboxgl.Map): HTMLElement {
    this.bindMap(map);
    this.remove();

    const container = document.createElement('div');
    container.className = 'mapboxgl-ctrl';
    container.style.width = '300px';
    container.appendChild(this);

    return container;
  }

  // eslint-disable-next-line custom-elements/no-method-prefixed-with-on
  onRemove(): void {
    this.remove();
    this.unbindMap();
  }

  getDefaultPosition(): string {
    return 'top-right';
  }
}

declare global {
  interface Window {
    MapboxSearchBox: typeof MapboxSearchBox;
  }
}

window.MapboxSearchBox = MapboxSearchBox;

if (!window.customElements.get('mapbox-search-box')) {
  customElements.define('mapbox-search-box', MapboxSearchBox);
}
